<template>
    <div class="brand-list" v-loading="loading">
        <div class="brand-head">
            <h2>
                <img src="@/assets/images/city-left.png" />
                {{ cityDetail.title }}
                <img src="@/assets/images/city-right.png" />
            </h2>
        </div>
        <!-- banner -->
        <div class="banner-box">
            <div class="banner-info">
                <h3>{{ cityDetail.title }}</h3>
                <p>分站介绍：{{ cityDetail.desc }}</p>
            </div>
            <div class="banner">
                <el-carousel height="430px" v-if="bannerList.length > 0">
                    <el-carousel-item v-for="item in bannerList" :key="item">
                        <el-image :src="$img(item.path)" fit="cover" />
                    </el-carousel-item>
                </el-carousel>
                <el-carousel height="430px" v-else>
                    <el-carousel-item v-for="item in adList" :key="item.adv_id">
                        <el-image :src="$img(item.adv_image)" fit="cover"
                            @click="$router.pushToTab(item.adv_url.url)" />
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <div class="areaFilters">
            <div class="areaFilters--label">区/县</div>
            <div class="areaFilters--content">
                <div class="city">
                    <div class="items" :class="filters.area == '430000000000' ? 'active' : ''"
                        @click="handleClearCity('area')">不限</div>
                    <div class="items" :class="item.id == filters.area ? 'active' : ''"
                        v-for="(item, index) in cityDetail.district_list" :key="index"
                        @click="handleSelectCity(item.id)">{{ item.name }}</div>
                </div>
            </div>
        </div>

        <!-- 城市分站 -->
        <div class="recommendGoods">
            <div class="module-list-head">
                <h2><span></span>推荐产品</h2>
            </div>
            <div class="module-list-body">
                <div class="card-carousel-wrapper">
                    <div class="card-carousel--nav__left" @click="moveCarousel(-1)" :disabled="atHeadOfList"></div>
                    <div class="card-carousel">
                        <div class="card-carousel--overflow-container">
                            <div class="card-carousel-cards"
                                :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')' }">
                                <div class="card-carousel--card" v-for="item in recommendGoodsList" :key="item.goods_id"
                                    @click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
                                    <div class="goods-wrap">
                                        <div class="img-wrap"><img alt="商品图片"
                                                :src="$img(item.goods_image[0], { size: 'mid' })"
                                                @error="imageError(index)" /></div>
                                        <h3>{{ item.goods_name }}</h3>
                                        <p class="desc">{{ item.introduction }}</p>
                                        <p class="price">
                                            <span class="num">￥{{ item.settle_price }}元</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-carousel--nav__right" @click="moveCarousel(1)" :disabled="atEndOfList"></div>
                </div>
            </div>
        </div>
        <div class="feed-tab-wrapper">
            <ul class="feed-tab">
                <li class="feed-tab__item" :class="filters.category_id === 0 ? 'feed-tab__item--active' : ''"
                    @click="selectedAllCategoryGoods()">
                    <div class="feed-tab__item-title"><span class="feed-tab__item-title-text">全部</span></div>
                </li>
                <li class="feed-tab__item" v-for="(item, index) in categoryList" :key="index"
                    :class="filters.category_id === item.category_id ? 'feed-tab__item--active' : ''"
                    @click="selectedCategoryGoods(item)">
                    <div class="feed-tab__item-title"><span
                            class="feed-tab__item-title-text">{{ item.category_name }}</span></div>
                </li>
            </ul>
        </div>
        <div class="list-wrap">
            <div class="cargo-list" v-if="cargoList.length">
                <div class="goods-info">
                    <div class="item" v-for="(item, index) in cargoList" :key="item.goods_id"
                        @click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
                        <div class="goods-wrap">
                            <div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image, { size: 'mid' })"
                                    @error="imageError(index)" /></div>
                            <h3>{{ item.goods_name }}</h3>
                            <p class="desc">{{ item.introduction }}</p>
                            <p class="price">
                                <span class="num">￥{{ item.discount_price }}元</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="pager">
                    <el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
                        :current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange"
                        @current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
                </div>
            </div>
            <div class="empty" v-else>
                <div class="ns-text-align">没有找到您想要的商品。换个条件试试吧</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { adList } from "@/api/website";
import { goodsSkuPage } from "@/api/goods/goods";
import { cityBannerList, websiteDetail, recommendGoodsList } from "@/api/goods/goods";
import { getChildCategory } from "@/api/goods/goodscategory";

export default {
    name: "list",
    components: {

    },
    computed: {
        atEndOfList() {
            return this.currentOffset <= (this.paginationFactor * -1) * (this.recommendGoodsList.length - this.windowSize);
        },
        atHeadOfList() {
            return this.currentOffset === 0;
        },
        ...mapGetters(["defaultGoodsImage"])
    },
    data: () => {
        return {
            currentOffset: 0,
            windowSize: 5,
            paginationFactor: 215,
            recommendGoodsList: [],
            categoryList: [],
            total: 0,
            keyword: "",
            currentPage: 1,
            pageSize: 12,
            adList: [],
            cityDetail: {},
            bannerList: [],
            cargoList: [],
            filters: {
                area_id: '430000000000',
                website_id: 1802,
                site_id: 0,
                category_id: 0,
                category_level: 0,
                type_id: 0,
            },
            loading: true,
        }
    },
    created() {
    },
    mounted() {
        this.getCityBanner() //图片列表
        this.getCityDetail() //城市详情
        this.getCategory() //菜单
    },
    methods: {
        moveCarousel(direction) {
            if (direction === 1 && !this.atEndOfList) {
                this.currentOffset -= this.paginationFactor;
            } else if (direction === -1 && !this.atHeadOfList) {
                this.currentOffset += this.paginationFactor;
            }
        },
        handleSelectCity(item,) {
            this.filters.area_id = item
            this.filters.area_level = 2
            this.getGoodsList() //产品列表
        },
        handleClearCity() {
            this.filters.area_id = '430000000000'
            this.getGoodsList() //产品列表
        },
        getAdList() {
            adList({ keyword: "NS_PC_INDEX" })
                .then(res => {
                    this.adList = res.data.adv_list;
                    for (let i = 0; i < this.adList.length; i++) {
                        if (this.adList[i].adv_url)
                            this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url);
                    }
                })
                .catch(err => err);
        },
        selectedCategoryGoods(item) {
            this.filters.category_id = item.category_id
            this.filters.category_level = item.level
            this.getGoodsList() //产品列表
        },
        selectedAllCategoryGoods() {
            this.filters.category_id = 0
            this.filters.category_level = 0
        },
        getCategory() {
            getChildCategory({
                level: 1,
                pid: 0
            }).then(res => {
                if (res.code == 0 && res.data) {
                    // console.log('分类数据', res.data)
                    this.categoryList = res.data
                }
            })
                .catch(err => {
                    this.$message.error(err.message)
                })


        },
        //获取banner
        getCityBanner() {
            cityBannerList({ site_id: this.filters.website_id })
                .then(res => {
                    if (res.code == 0) {
                        this.bannerList = res.data;
                    }
                })
                .catch(err => {
                    this.loading = false;
                    this.$message.error(err.message);
                });
        },
        //获取详情
        getCityDetail() {
            websiteDetail({ site_id: this.filters.website_id })
                .then(res => {
                    if (res.code == 0) {
                        this.cityDetail = res.data;
                        this.filters.area_id = res.data.city_id;

                        this.getGoodsList() //产品列表
                        this.getRecommendGoodsList() //推荐产品
                    }
                })
                .catch(err => {
                    this.loading = false;
                    this.$message.error(err.message);
                });
        },
        //获取详情
        getRecommendGoodsList() {
            recommendGoodsList({ site_id: this.filters.website_id })
                .then(res => {
                    if (res.code == 0) {
                        this.recommendGoodsList = res.data.list;
                    }
                })
                .catch(err => {
                    this.loading = false;
                    this.$message.error(err.message);
                });
        },
        getGoodsList() {
            const params = {
                page: this.currentPage,
                page_size: this.pageSize,
                keyword: this.keyword,
                ...this.filters
            }
            goodsSkuPage(params || {})
                .then(res => {
                    const { count, page_count, list } = res.data
                    this.total = count
                    this.cargoList = list
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                })
        },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.getGoodsList()
        },
        handleCurrentPageChange(page) {
            this.currentPage = page
            this.getGoodsList()
        },
        imageError(index) {
            this.cargoList[index].sku_image = this.defaultGoodsImage;
        }
    }
};
</script>

<style lang="scss">
.el-header,
.header {
    height: 135px !important;
}

.header .nav-box {
    display: none !important;
}

.brand-head {
    height: 60px;
    margin: 20px 0px 10px;
    text-align: center;

    h2 {
        font-size: 36px;
        color: $base-color;
    }
}

.banner-box {
    width: 1210px;
    background: #fff;
    margin: 0px auto;
    padding: 0;
    height: 430px;

    .banner-info {
        background: url("../../../assets/images/city-bg.png") no-repeat center center;
        width: 210px;
        height: 430px;
        overflow: hidden;
        float: left;
        color: $base-color;

        h3 {
            min-height: 24px;
            font-size: 20px;
            margin: 30px 30px 15px;
        }

        p {
            font-size: 13px;
            line-height: 18px;
            margin: 0px 30px 30px;
            text-align: left;
            overflow: hidden;
        }
    }

    .banner {
        width: 1000px;
        height: 430px;
        position: relative;
        float: left;
    }
}

.areaFilters {
    width: 1210px;
    background: #fff;
    margin: 20px auto;
    padding: 0;
    height: 40px;
    position: relative;
    border: 2px solid $base-color-bg;
    border-radius: 8px;

    .areaFilters--label {
        float: left;
        margin-right: 20px;
        line-height: 40px;
        text-align: right;
        font-size: 16px;
        color: #666;
        width: 5%;
    }

    .areaFilters--content {
        float: left;
        width: 90%;

        .items {
            display: inline-block;
            padding: 0 10px;
            cursor: pointer;
            font-size: 16px;
            line-height: 40px;
            color: #999;
        }

        .items:hover,
        .items.active {
            color: $base-color;
        }
    }
}

.recommendGoods {
    width: 1210px;
    display: block;
    margin: 0px auto 20px;
    background-color: #fff;
    border-radius: 8px;
    border-radius: 8px;

    .module-list-head {
        padding: 0px;
        margin: 0;

        h2 {
            line-height: 42px;
            color: #333;
            padding: 10px 0px;
            font-size: 22px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;

            span {
                border: 3px solid $base-color;
                border-radius: 6px;
                height: 10px;
                margin: 0px 10px;
                display: inline-block;
            }
        }
    }

    .module-list-body {
        padding: 0px 10px;
    }
}

.card-carousel-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    .card-carousel {
        justify-content: center;
        width: 1075px;
        display: flex;
        position: relative;
        overflow: hidden;
        height: 295px;

        .card-carousel-cards {
            display: flex;
            transition: transform 150ms ease-out;
            transform: translatex(0px);

            .card-carousel--card {
                background: #fff;
                cursor: pointer;
                padding: 0;
                transition: all 0.2s linear;
                z-index: 3;
                margin: 0 10px 20px;
                box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
                border-radius: 8px;

                .goods-wrap {
                    width: 195px;
                    padding: 15px 0px;

                    .img-wrap {
                        width: 155px;
                        height: 155px;
                        margin: 0px auto;
                        text-align: center;
                        line-height: 160px;
                        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
                        border-radius: 16px;
                        overflow: hidden;
                        position: relative;

                        img {
                            border: none;
                            position: absolute;
                            margin-right: -75px;
                            top: 50%;
                            left: 50%;
                            display: block;
                            transform: translate(-50%, -50%);
                        }
                    }

                    h3 {
                        font-size: 15px;
                        line-height: 24px;
                        text-align: center;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        margin: 10px 10px 5px;
                    }

                    .desc {
                        margin: 0 10px;
                        height: 14px;
                        font-size: 13px;
                        line-height: 14px;
                        color: #b0b0b0;
                        text-align: center;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    .price {
                        margin: 0 10px;
                        font-size: 13px;
                        text-align: center;
                        color: $base-color;

                        del {
                            margin-left: 0.5em;
                            color: #b0b0b0;
                        }
                    }
                }

                &:hover {
                    z-index: 2;
                    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                    -webkit-transform: translate3d(0, -2px, 0);
                    transform: translate3d(0, -2px, 0);
                }
            }

            .card-carousel--card:first-child {
                margin-left: 10px;
            }

            .card-carousel--card:last-child {
                margin-right: 10px;
            }
        }
    }

    .card-carousel--overflow-container {
        overflow: hidden;
        padding: 10px 0px;
    }

    .card-carousel--nav__left,
    .card-carousel--nav__right {
        display: inline-block;
        width: 20px;
        height: 20px;
        padding: 0;
        box-sizing: border-box;
        border-top: 2px solid $base-color;
        border-right: 2px solid $base-color;
        cursor: pointer;
        margin: 0 10px;
        transition: transform 150ms linear;
    }

    .card-carousel--nav__left[disabled],
    .card-carousel--nav__right[disabled] {
        opacity: 0.2;
        border-color: black;
    }

    .card-carousel--nav__left {
        transform: rotate(-135deg);
    }

    .card-carousel--nav__left:active {
        transform: rotate(-135deg) scale(0.9);
    }

    .card-carousel--nav__right {
        transform: rotate(45deg);
    }

    .card-carousel--nav__right:active {
        transform: rotate(45deg) scale(0.9);
    }
}


.feed-tab-wrapper {
    width: 1170px;
    padding: 0px 20px;
    background-color: $base-color-bg;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .feed-tab {
        width: 1170px;
        margin: 0 auto;
        height: 48px;
        display: flex;

        .feed-tab__item {
            position: relative;
            height: 42px;
            margin-top: 6px;
            width: 168px;
            float: left;
            text-align: center;
            cursor: pointer;

            .feed-tab__item-title {
                color: #fff;
                font-size: 16px;
                font-weight: 700;
                line-height: 42px;
            }
        }

        .feed-tab__item.feed-tab__item--active {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            background-color: #fff;

            .feed-tab__item-title {
                color: $base-color;
                font-size: 17px;
            }
        }
    }
}


.list-wrap {
    width: 1190px;
    overflow: hidden;
    min-height: 500px;
    background-color: #fff;
    padding: 10px 10px 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .goods-info {
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 20%;
            margin: 0;
            background: #fff;
            cursor: pointer;
            padding: 0;
            transition: all 0.2s linear;

            .goods-wrap {
                margin: 0 10px 20px;
                padding: 15px 0px;
                box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
                border-radius: 8px;

                &:hover {
                    z-index: 2;
                    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                    -webkit-transform: translate3d(0, -2px, 0);
                    transform: translate3d(0, -2px, 0);
                }

                .img-wrap {
                    width: 155px;
                    height: 155px;
                    margin: 0px auto;
                    text-align: center;
                    line-height: 160px;
                    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
                    border-radius: 16px;
                    overflow: hidden;
                    position: relative;

                    img {
                        border: none;
                        position: absolute;
                        margin-right: -75px;
                        top: 50%;
                        left: 50%;
                        display: block;
                        transform: translate(-50%, -50%);
                    }
                }

                h3 {
                    font-size: 15px;
                    line-height: 24px;
                    text-align: center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    margin: 10px 10px 5px;
                }

                .desc {
                    margin: 0 10px;
                    height: 14px;
                    font-size: 13px;
                    line-height: 14px;
                    color: #b0b0b0;
                    text-align: center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .price {
                    margin: 0 10px;
                    font-size: 13px;
                    text-align: center;
                    color: $base-color;

                    del {
                        margin-left: 0.5em;
                        color: #b0b0b0;
                    }
                }
            }
        }
    }
}

.empty {
    margin-top: 45px;
}

.pager {
    text-align: center;
    margin: 15px 0;
}
</style>
